import PropTypes from 'prop-types';
import classnames from 'classnames';
import pageLinks from 'dibs-pagelinks';

import { SharedPaginationItem } from './SharedPaginationItem';

import styles from './SharedPagination.scss';

function SharedPaginationComponent({
    numberOfPages,
    currentPage,
    totalPageLinks,
    uriRef,
    onLinkClick,
    hideHref,
}) {
    if (numberOfPages <= 1) {
        return null;
    }

    const generatedPageLinks = pageLinks(numberOfPages, currentPage, totalPageLinks);

    return (
        <ul className={styles.paginationList} data-tp={numberOfPages} data-tn="pagination-list">
            {generatedPageLinks.map(({ type, key, pageNumber, isSelected }) => (
                <li
                    key={key}
                    className={classnames(styles.paginationItem, {
                        [styles.page]: type === 'page',
                        [styles.arrowWrapper]: ['back', 'forward'].includes(type),
                    })}
                >
                    <SharedPaginationItem
                        type={type}
                        pageNumber={pageNumber}
                        isSelected={isSelected}
                        currentPage={currentPage}
                        uriRef={uriRef}
                        onLinkClick={onLinkClick}
                        hideHref={hideHref}
                    />
                </li>
            ))}
        </ul>
    );
}

SharedPaginationItem.defaultProps = {
    onLinkClick: () => {},
};

SharedPaginationComponent.propTypes = {
    currentPage: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    totalPageLinks: PropTypes.number,
    uriRef: PropTypes.string.isRequired,
    onLinkClick: PropTypes.func,
    hideHref: PropTypes.bool,
};

export { SharedPaginationComponent };
