import PropTypes from 'prop-types';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import styles from './arrow.scss';

export const Arrow = ({ isLeft }) => {
    const classes = `${styles.arrow} ${isLeft ? styles.isLeft : ''}`;

    return (
        <span className={classes}>
            <ArrowRight />
        </span>
    );
};

Arrow.propTypes = {
    isLeft: PropTypes.bool,
};
