import PropTypes from 'prop-types';
import { FormattedMessage } from 'dibs-react-intl';

import { getNewPaginationUriRef } from '../../utils/uriUtils';

import { SharedPaginationArrowItem } from './SharedPaginationArrowItem';
import { SharedPaginationPageItem } from './SharedPaginationPageItem';

function SharedPaginationItem({
    type,
    pageNumber,
    isSelected,
    currentPage,
    uriRef,
    onLinkClick,
    hideHref,
}) {
    switch (type) {
        case 'back': {
            const href = getNewPaginationUriRef(uriRef, currentPage - 1);
            return (
                <SharedPaginationArrowItem
                    isLeft
                    onClick={e => onLinkClick({ e, href, pageNumber: currentPage - 1 })}
                    dataTn={`page-${type}`}
                    href={hideHref ? null : href}
                >
                    <FormattedMessage id="SharedPagination.previous" defaultMessage="Previous" />
                </SharedPaginationArrowItem>
            );
        }
        case 'page': {
            const dataTn = `page-${pageNumber}${isSelected ? '-selected' : ''}`;
            const href = getNewPaginationUriRef(uriRef, pageNumber);
            return (
                <SharedPaginationPageItem
                    isSelected={isSelected}
                    dataTn={dataTn}
                    onClick={e => onLinkClick({ e, href, pageNumber })}
                    href={hideHref ? null : href}
                >
                    {pageNumber}
                </SharedPaginationPageItem>
            );
        }
        case 'ellipsis': {
            return <span>...</span>;
        }
        case 'forward': {
            const href = getNewPaginationUriRef(uriRef, currentPage + 1);
            return (
                <SharedPaginationArrowItem
                    onClick={e => onLinkClick({ e, href, pageNumber: currentPage + 1 })}
                    dataTn={`page-${type}`}
                    href={hideHref ? null : href}
                >
                    <FormattedMessage id="SharedPagination.next" defaultMessage="Next" />
                </SharedPaginationArrowItem>
            );
        }
        default: {
            return null;
        }
    }
}

SharedPaginationItem.propTypes = {
    type: PropTypes.string.isRequired,
    pageNumber: PropTypes.number,
    isSelected: PropTypes.bool,
    currentPage: PropTypes.number.isRequired,
    uriRef: PropTypes.string.isRequired,
    onLinkClick: PropTypes.func.isRequired,
    hideHref: PropTypes.bool,
};

export { SharedPaginationItem };
