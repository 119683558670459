import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';

import styles from './SharedPaginationPageItem.scss';

function SharedPaginationPageItem({ children, href, isSelected, onClick, dataTn }) {
    return isSelected ? (
        <span className={classnames(styles.item, styles.isSelected)} data-tn={dataTn}>
            {children}
        </span>
    ) : (
        <Link href={href} className={styles.item} dataTn={dataTn} onClick={onClick}>
            {children}
        </Link>
    );
}

SharedPaginationPageItem.propTypes = {
    children: PropTypes.node.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    dataTn: PropTypes.string,
    href: PropTypes.string,
};

export { SharedPaginationPageItem };
