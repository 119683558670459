/**
 * @generated SignedSource<<03bf47c1e58a85552a4089485540bf2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ecommerceTrackingProductData_item$data = {
  readonly browseUrl: string | null;
  readonly categoryCode: string | null;
  readonly contemporaryTrackingString: string;
  readonly displayPriceTracking: ReadonlyArray<{
    readonly amountType: string | null;
    readonly convertedAmountList: ReadonlyArray<{
      readonly amount: number | null;
      readonly currency: Currency | null;
    } | null> | null;
    readonly textType: string | null;
  } | null> | null;
  readonly isNewListing: boolean | null;
  readonly personalizationType: string | null;
  readonly seller: {
    readonly serviceId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "ecommerceTrackingProductData_item";
};
export type ecommerceTrackingProductData_item$key = {
  readonly " $data"?: ecommerceTrackingProductData_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTrackingProductData_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ecommerceTrackingProductData_item"
};

(node as any).hash = "4161b9d472ed236f5022fe97e8eb3259";

export default node;
