import { createBrowserHistory } from 'history';
import { stripRoutingSegment } from 'dibs-buyer-layout/exports/stripRoutingSegment';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

const getCurrentUriRef = ({ pathname, search }) => {
    const currentUriRef = stripRoutingSegment(`${pathname}${search}`);
    return decodeURIComponent(currentUriRef);
};

export class FindingRouter {
    constructor(options) {
        const { routes, initialUriRef } = options;

        this.routes = routes;
        this.history = createBrowserHistory();
        this.previousDisplayUriRef = handleLocaleUrl(initialUriRef, GLOBAL_CLIENT_ONLY_LOCALE);
    }

    navigate(displayUriRef, options = {}) {
        const { replace } = options;
        const { pathname, search, hash } = this.history.location;
        const currentUriRef = handleLocaleUrl(
            getCurrentUriRef({ pathname, search, hash }),
            GLOBAL_CLIENT_ONLY_LOCALE
        ); // what's currently in the browser address bar minus oq hash param
        const urlToSet = handleLocaleUrl(displayUriRef, GLOBAL_CLIENT_ONLY_LOCALE);

        if (urlToSet !== currentUriRef) {
            if (replace) {
                this.history.replace(urlToSet);
            } else {
                //update browser url bar if URI changes in the store
                this.history.push(urlToSet);
                // keep track of previous push so we don't push any history twice
                this.previousDisplayUriRef = urlToSet;
            }
        }
    }
}
