export const getPriceType = ({
    amountType,
    textType,
}: {
    amountType: string | null | undefined;
    textType: string | null | undefined;
}): string => {
    let priceType = 'list price';

    if (textType === 'SOLD') {
        priceType = 'item sold';
    } else if (textType === 'UNAVAILABLE') {
        priceType = 'item unavailable';
    } else if (amountType === 'WEEKLY_SALE') {
        priceType = 'saturday sale';
    } else if (amountType === 'NET') {
        priceType = 'net price';
    }

    return priceType;
};
