import { pageTypeConstants } from './pageTypeConstants';

export const ATTRIBUTE_CARATWEIGHT = 'carat-weight';
export const ATTRIBUTE_COLOR = pageTypeConstants.COLOR;
export const ATTRIBUTE_CREATOR = pageTypeConstants.CREATOR;
export const ATTRIBUTE_CUSTOMIZABLE = 'customizable';
export const ATTRIBUTE_ITEMTYPE = 'item_type';
export const ATTRIBUTE_JEWELRYTYPE = 'jewelry_type';
export const ATTRIBUTE_LEADTIMEBUCKET = 'lead_time_bucket';
export const ATTRIBUTE_MATERIAL = pageTypeConstants.MATERIAL;
export const ATTRIBUTE_METAL = pageTypeConstants.METAL;
export const ATTRIBUTE_ORIGIN = pageTypeConstants.ORIGIN;
export const ATTRIBUTE_PERIOD = pageTypeConstants.PERIOD;
export const ATTRIBUTE_ROOM = pageTypeConstants.ROOM;
export const ATTRIBUTE_STONE = pageTypeConstants.STONE;
export const ATTRIBUTE_STONECUT = 'stone-cut';
export const ATTRIBUTE_STYLE = pageTypeConstants.STYLE;
export const ATTRIBUTE_TECHNIQUE = pageTypeConstants.TECHNIQUE;

export const ECOMMERCE_ATTRIBUTES = [
    ATTRIBUTE_CARATWEIGHT,
    ATTRIBUTE_COLOR,
    ATTRIBUTE_MATERIAL,
    ATTRIBUTE_METAL,
    ATTRIBUTE_ORIGIN,
    ATTRIBUTE_PERIOD,
    ATTRIBUTE_ROOM,
    ATTRIBUTE_STONE,
    ATTRIBUTE_STONECUT,
    ATTRIBUTE_STYLE,
    ATTRIBUTE_TECHNIQUE,
];
